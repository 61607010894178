import React from 'react';
import { Descriptions } from 'antd';
import { Breadcrumbs } from '../../components';
import { useOrganizationDkPage } from './useOrganizationDkPage';
import Index from '../../components/Spinner';
import { Link } from 'react-router-dom';
import { CustomButton } from '../../components/Button/Button';
import { getFolderIdUrl } from '../../../utils/getFolderId';
import './index.scss';
import { OrganizationAddFileModal } from './OrganizationAddFileModal';
import { modalApi } from '../../../redux/actions/modal';

const OrganizationDkPage = () => {
  const {
    linkRoute,
    handleConfirmDeletefile,
    downloadFile,
    fetchData,
    id,
    dispatch,
    handleConfirmDeleteOrganization,
    sendExtractsById,
    loading,
    response,
    params,
  } = useOrganizationDkPage();

  if (loading) {
    return (
      <div className="w-full flex justify-center">
        <Index size={60} />
      </div>
    );
  }

  return (
    <div>
      <Breadcrumbs liClassName="breadcrumbs-li" linkRoute={linkRoute} />

      <div className="card">
        <div className="card-header flex-wrap jc-between d-flex ai-center">
          Организация
          <div className="flex gap-2 items-center">
            <CustomButton view="danger" onClick={handleConfirmDeleteOrganization}>
              Удалить из протокола
            </CustomButton>

            <CustomButton onClick={sendExtractsById} view="warning">
              Отправить выписку
            </CustomButton>

            <Link to={`/organization-update/${id}${getFolderIdUrl(null, { protocolId: params.protocolId })}`}>
              <button className="btn btn-primary">Изменить информацию</button>
            </Link>
          </div>
        </div>
      </div>
      <Descriptions className=" mb-4" bordered column={1}>
        <Descriptions.Item style={{ padding: '10px' }} label="Статус">
          {response.isExtractSent ? 'Отправлено' : 'Не отправлено'}
        </Descriptions.Item>
        <Descriptions.Item style={{ padding: '10px' }} label="Наименование">
          {response.organization.name || '-'}
        </Descriptions.Item>
        <Descriptions.Item style={{ padding: '10px' }} label="ОГРН">
          {response.organization.ogrn || '-'}
        </Descriptions.Item>
        <Descriptions.Item style={{ padding: '10px' }} label="ИНН">
          {response.organization.inn || '-'}
        </Descriptions.Item>
        <Descriptions.Item style={{ padding: '10px' }} label="Основной">
          <div
            className="organization-info-main-text"
            dangerouslySetInnerHTML={{ __html: response.main_text || '-' }}
          />
        </Descriptions.Item>
        <Descriptions.Item style={{ padding: '10px' }} label="Голосование">
          {response.voting_text || '-'}
        </Descriptions.Item>
        <Descriptions.Item style={{ padding: '10px' }} label="Решение">
          {response.solve_text || '-'}
        </Descriptions.Item>
      </Descriptions>

      <div className="card-header justify-between flex !mt-6">
        Документ
        {!response.isHasDoc && (
          <CustomButton onClick={() => dispatch(modalApi('organization-add-file'))} className="btn btn-primary btn-xs">
            Добавить файл
          </CustomButton>
        )}
      </div>
      <div className="scroll-x card-content !px-0">
        <table className="mt-1 !mx-0 middle-table">
          <thead>
            <tr>
              <th>Название</th>
              <th align="right" className="settings capitalize justify-end flex">
                действия
              </th>
            </tr>
          </thead>

          <tbody>
            {response.isHasDoc && (
              <tr>
                <td>Требование</td>
                <td align="right">
                  <div className="space-x-2">
                    <CustomButton onClick={handleConfirmDeletefile} className="btn btn-danger btn-xs">
                      Удалить файл
                    </CustomButton>
                    <CustomButton onClick={downloadFile} className="btn btn-primary btn-xs">
                      Скачать файл
                    </CustomButton>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>

        {!response.isHasDoc && <span className="w-full flex justify-center pt-4"> Нет документов</span>}
      </div>

      <OrganizationAddFileModal fetchData={fetchData} />
    </div>
  );
};

export default OrganizationDkPage;
